<template>
	<div>
	<div class="framiactusection">
	<div class="framiactubutton">
		<i :class="`fa fa-${$t(`preamble.buttonicon`)} mx-2 fc-f7`"></i>
		<a :href="url" v-html="$t(`preamble.button`)"></a>
	</div>
	</div>
    <Part section="preamble" :chapters="chapters" />
	</div>
</template>
<script>
import Part from './Part.vue';
export default {
    name: 'Preamble',
    components: {
        Part
    },
    data() {
        return {
            chapters: {
                default: {
                    type: 'two',
                    quote: false,
                    title: false,
                },
			},
			url: '#',
        }
    },
  created() {
      this.getArticles();
    },
  methods: {
    getArticles() {
      let url = 'https://framablog.org/tag/framiactu/json';
      fetch(url)
        .then(res => res.json())
        .then((data) => {
          data.items.every((item) => {
            if (!item.tags.includes('English')
				&& this.url == '#') { 
              this.url = item.url;
			  return false;
            }
          });
        })
        .catch((err) => { console.error(err) }); // eslint-disable-line
    },
  },
};
</script>
<style>
.framiactubutton {
	@media (min-width: 768px) {
		text-align: right;
	}
	@media (max-width: 768px) {
		text-align: center;
	}
	text-decoration: none;
	font-family: Tovari Sans, sans-serif;
	font-size: 2em;
	padding: 0.4em 0.8em;
	background-color: #eee;
	border-radius: 12px;
	margin-right: 3%;
	margin-left: 3%;
}

.framiactusection {
	display: flex;
	@media (min-width: 768px) {
		justify-content: right;
	}
	@media (max-width: 768px) {
		justify-content: center;
	}
}
</style>

